import { createSelector } from 'reselect';

const PROVIDER_ID_MAP = {
    'alex-makalinao': 1,
    'arsalan-salamat': 2,
    'vatsal-patel': 3,
    'carlos-lopez': 4,
    'catherine-connor': 5,
    'foluso-ogunleye': 6,
    'gregory-dean': 7,
    'irene-kyritsis': 8,
    'jacqueline-malekirad': 9,
    'jacqueline-webster': 10,
    'janice-enriquez': 11,
    'john-ellerton': 12,
    'josephine-cruz': 13,
    'karissa-tan': 14,
    'khawaja-jahangir': 15,
    'mary-esele': 16,
    'michael-becker': 17,
    'pamela-kurtzhals': 19,
    'rachael-taylor': 20,
    'ritchie-stevens': 21,
    'robert-beck': 22,
    'russell-gollard': 23,
    'carlos-araujo': 24,
    'edgardo-faylona': 25,
    'vinh-nguyen': 26,
    'hannah-furney': 27,
    'pinky-linatoc': 28,
    'eric-gershman': 29,
    'ronald-ganzon': 30,
    'darla-adams': 31,
    'jackline-duana': 32,
    'matthew-paul-miller': 33,
    'kechukwu-nwabueze': 34,
    'swetha-pentapati': 35,
    'michael-christensen': 36,
    'daniel-brunnhoelzl': 37,
    "sarah-rueff-ryan": 38
}

const selectProviders = state => state.provider;

export const selectProviderSections = createSelector(
    [selectProviders],
    provider => provider.sections
)

export const selectIndividualProvider = providerUrlParam =>
    createSelector(
        [selectProviderSections],
        providers =>
            providers.find(
                provider => provider.id === PROVIDER_ID_MAP[providerUrlParam]
            )
    );
