import placeHolderMaleGrid from "../../img/providers/Male-Card.png";
import placeHolderFemaleGrid from "../../img/providers/Female-Card.png";

import femalePlaceholder from "../../img/providers/Female-Placeholder.png";
import malePlaceholder from "../../img/providers/Male-Placeholder.png";

import robertBeckgrid from "../../img/providers/grid/Beck_Robert_MD_grid.png";
import robertBeck from "../../img/providers/interior/Beck_Robert_MD.png";

import michaelBeckergrid from "../../img/providers/grid/Becker_Michael_DO_grid.png";
import michaelBecker from "../../img/providers/interior/Becker_Michael_DO.png";

import johnEllertongrid from "../../img/providers/grid/Ellerton_John_MD_grid.png";
import johnEllerton from "../../img/providers/interior/Ellerton_John_MD.png";

import matthewMillergrid from "../../img/providers/grid/MatthewMiller.png";
import matthewMiller from "../../img/providers/interior/MatthewMiller_MD.png";

import russellGollardgrid from "../../img/providers/grid/Gollard_Russell_MD_grid.jpg";
import russellGollard from "../../img/providers/interior/Russell_Gollard.png";

import JahangirKhawajaSaadgrid from "../../img/providers/grid/Jahangir_KhawajaSaad_MD_grid.jpg";
import KhawajaJahangir from "../../img/providers/interior/Khawaja_Jahangir.png";

import vatsalPatelgrid from "../../img/providers/grid/Patel_Vatsal_302x242 (1).jpg";
import vatsalPatel from "../../img/providers/interior/Patel_Vatsal_330x456 (1).jpg";

import ritchieStevensgrid from "../../img/providers/grid/ritchie_stevens_grid.png";
import ritchieStevens from "../../img/providers/interior/Ritchie_Stevens.png";

import karissaTrangrid from "../../img/providers/grid/karissa_tan_grid.png";
import karissaTran from "../../img/providers/interior/Karissa_Tan.png";

import rachaelTaylorgrid from "../../img/providers/grid/rachel_taylor_grid.png";
import rachaelTaylor from "../../img/providers/interior/Rachael_Taylor.png";

import beauToygrid from "../../img/providers/grid/bean_toy_gird.png";
import beauToy from "../../img/providers/interior/Beau_Toy.png";

import anaManaliligrid from "../../img/providers/grid/Manalili_Ana_302x242 (1).png";
import anaManalili from "../../img/providers/interior/Manalili_Ana_330x456 (1).png";

import edgardoFaylonaGrid from "../../img/providers/grid/edgardo_faylona_grid.png";
import edgardoFaylona from "../../img/providers/interior/edgardo_faylona.png";

import Salamat from "../../img/providers/interior/Arsalan_Salamat.png";
import Salamat_grid from "../../img/providers/grid/Arsalan_Salamatgrid.png";

import pinkyGrid from "../../img/providers/grid/pinky_grid.png";
import pinkyInterior from "../../img/providers/interior/pinky_interior.png";

import maryEsele from "../../img/providers/interior/Esele_Mary.jpg";
import maryEseleGrid from "../../img/providers/grid/EseleMary_grid.jpg";

import gershman from "../../img/providers/interior/Eric_Gershman.png";
import gershmanGrid from "../../img/providers/grid/eric_gershman_grid2.png";

import darlaAdams from "../../img/providers/interior/21904-DarlaAdams_300x400.jpg";
import darlaAdamsGrid from "../../img/providers/grid/21904-DarlaAdams_300x240.jpg";

import okechukwuObiGrid from "../../img/providers/grid/OkechukwuObi_302x242.png";
import okechukwuObi from "../../img/providers/interior/OkechukwuObi_301x415.png";

import swethapentapatiGrid from "../../img/providers/grid/Swetha_Pentapati_302x242.png";
import swethapentapati from "../../img/providers/interior/Swetha_Pentapati_301x415.png";

import Hannah_Furney from "../../img/providers/interior/Hannah_Furney_301x415.png";
import Hannah_Furneygrid from "../../img/providers/grid/Hannah_Furney_302x242.png";

import Jackline_Duanagrid from "../../img/providers/grid/Jackline_Duana_302x242.png";
import Jackline_Duana from "../../img/providers/interior/Jackline_Duana_301x415.png";

import Foluso_Ogunley from "../../img/providers/interior/Foluso_Ogunleye_301x415.png";
import Foluso_Ogunleygrid from "../../img/providers/grid/Foluso_Ogunleye_302x242.png";

import Alex_Makilinaogrid from "../../img/providers/grid/Alex_Makilinao_301x415.png";
import Alex_Makilinao from "../../img/providers/interior/Alex_Makilinao_302x242.png";

import MichaelChristensen from "../../img/providers/interior/Michael Christensen_301x415.jpg";
import MichaelChristensengrid from "../../img/providers/grid/Michael Christensen_302x242.jpg";

import Daniel_Brunnhoelzlgrid from "../../img/providers/grid/Daniel_Brunnhoelzl_302x242.jpg";
import DanielBrunnhoelzl from "../../img/providers/interior/Daniel_Brunnhoelzl_301x415.jpg";

import SarahRyangrid from "../../img/providers/grid/Sarah_Ryan_302x242.jpg";
import SarahRyan from "../../img/providers/interior/Sarah_Ryan_330x456.jpg";

const PROVIDERS_DATA = {
  sections: [
    // {
    //   imageUrl: SarahRyangrid,
    //   providerName: "Sarah Rueff Ryan",
    //   providerTitle: "MD",
    //   linkUrl: "sarah-rueff-ryan",
    //   providerInteriorImage: SarahRyan,
    //   specialty: ["Radiation Oncology"],
    //   sex: "Female",
    //   providerBio: `Sarah Ryan, MD, is an experienced and caring urologist. Dr. Ryan specializes in female pelvic medicine and reconstructive surgery. She has presented at many conferences and written several papers. She loves to teach on important topics. Dr. Ryan cares deeply about making her patients’ lives better. Her hobbies include running, skiing and cooking.`,
    //   providerPhilosophy: [],
    //   languages: ["English"],
    //   qualifications: [
    //     {
    //       type: "Medical School",
    //       name: "University of Tennessee College of Medicine, Memphis, Tennessee",
    //     },
    //     {
    //       type: "Internship",
    //       name: "University of Tennessee Medical Center, Memphis, Tennessee",
    //     },
    //     {
    //       type: "Residency",
    //       name: "University of Tennessee Medical Center, Memphis, Tennessee",
    //     },
    //     {
    //       type: "Fellowship",
    //       name: "Cedars-Sinai Medical Center, Tower Urology, Los Angeles, California",
    //     },
    //     {
    //       type: "Board certifications",
    //       name: "American Board of Urology: Urology, Female Pelvic Medicine and Reconstructive Surgery",
    //     },
    //   ],
    //   organizations: [],
    //   professionalAssociations: [
    //     "American Urological Association: 2000–present",
    //     "Society of Women in Urology: 2003–present",
    //     "Society of Urodynamics and Female Urology: 2006–present",
    //     "University of Tennessee College of Medicine Alumni Association: 1998–present",
    //     "Converse College Alumni Association: 1994–present",
    //     "Montana Medical Association: 2005–present",
    //   ],
    //   publicationsAndResearch: [],
    //   providerLocations: [1],
    //   id: 38,
    // },

    {
      imageUrl: Daniel_Brunnhoelzlgrid,
      providerName: "Daniel Brunnhoelzl",
      providerTitle: "MD",
      linkUrl: "daniel-brunnhoelzl",
      providerInteriorImage: DanielBrunnhoelzl,
      specialty: ["Radiation Oncology"],
      sex: "Male",
      providerBio: `Daniel Brunnhoelzl, MD, helps people with cancer through radiation care. He’s worked at clinics in Texas, Alaska and now Nevada. His research has helped improve cancer care. Dr. Brunnhoelzl enjoys mentoring other doctors. In his spare time, he loves volunteering in his community. Patients trust Dr. Brunnhoelzl because he’s kind and smart and wants to help them feel better.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Creighton University School of Medicine PRC, Phoenix, AZ",
        },
        {
          type: "Residency",
          name: "University of North Dakota, Sanford Health, Fargo, ND \nBaylor College of Medicine, Houston, TX",
        },
        {
          type: "Board certifications",
          name: "•	Nevada State Board of Medical Examiners",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Board of Radiology (ABR)",
        "American Society for Radiation Oncology (ASTRO)",
        "Alpha Omega Alpha (AOA)",
        "American Society of Clinical Oncology (ASCO)",
      ],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 37,
    },

    {
      imageUrl: MichaelChristensengrid,
      providerName: "Michael T. Christensen",
      providerTitle: "MD, MBA",
      linkUrl: "michael-christensen",
      providerInteriorImage: MichaelChristensen,
      specialty: ["Radiation Oncology"],
      sex: "Male",
      providerBio: `Dr. Michael Christensen is a skilled radiation oncologist with a focus on advanced cancer care. He uses modern tools like Gamma Knife and CyberKnife to care for patients. Dr. Christensen likes to share his cancer knowledge through lectures and studies. He cares about helping doctors and patients talk to each other better. Outside of work, he enjoys teaching, research and community service.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Utah School of Medicine, Salt Lake City, UT",
        },
        {
          type: "Internship",
          name: "Riverside Community Hospital, Riverside, CA",
        },
        {
          type: "Residency",
          name: "University of Texas Southwestern Medical Center, Dallas, TX",
        },
        {
          type: "Fellowship",
          name: "University of California, Irvine",
        },
        {
          type: "Board certifications",
          name: "American Society for Radiation Oncology (ASTRO), American Society of Clinical Oncology (ASCO), Alpha Omega Alpha (AOA) Honor Society",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 36,
    },

    {
      imageUrl: Alex_Makilinao,
      providerName: "Alex Makalinao",
      providerTitle: "MD",
      linkUrl: "alex-makalinao",
      providerInteriorImage: Alex_Makilinaogrid,
      specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
      sex: "Male",
      providerBio: `Alex Makalinao, MD, is a board-certified in hematology and medical oncology. Dr. Makalinao focuses on putting his patients first by listening to their needs and addressing all concerns. He believes in patient education and shared decision making.  `,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of the Philippines, College of Medicine in Manila, Philippines",
        },
        {
          type: "Internship",
          name: "State University of New York, Mercy Hospital of Buffalo Consortium",
        },
        {
          type: "Residency",
          name: "State University of New York, Mercy Hospital of Buffalo Consortium",
        },
        {
          type: "Fellowship",
          name: "University of California, Irvine",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 1,
    },

    {
      imageUrl: matthewMillergrid,
      providerName: "Matthew Paul Miller",
      providerTitle: "MD",
      linkUrl: "matthew-paul-miller",
      providerInteriorImage: matthewMiller,
      specialty: ["Breast Surgical Oncology"],
      sex: "Male",
      providerBio: `Matthew Miller, MD, has been a great surgeon for many years. He's known for his excellent skills, and specializes in breast surgical oncology. Dr. Miller keeps up to date on the latest in the field. He loves to research and has published several studies in different areas. Dr. Miller takes the time to make sure each patient knows their care choices. He provides support throughout their cancer journey. He’s a kind doctor and focused on making his patients' lives better.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Pittsburgh School of Medicine, Pittsburgh, PA",
        },
        {
          type: "Internship",
          name: "Rhode Island Hospital/Brown University, Providence, RI",
        },
        {
          type: "Residency",
          name: "Guthrie Robert Packer Hospital, Sayre, PA. Virginia Commonwealth University, Richmond, VA",
        },
        {
          type: "Fellowship",
          name: `“Breast Surgical Oncology" fellowship at the University of Rochester Medical Center in Rochester, NY`,
        },
        {
          type: "Board certifications",
          name: "American Board of Surgery, General Surgery",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 33,
    },

    {
      imageUrl: Salamat_grid,
      providerName: "Arsalan Salamat",
      providerTitle: "MD",
      linkUrl: "arsalan-salamat",
      providerInteriorImage: Salamat,
      specialty: ["General Surgery"],
      sex: "Male",
      providerBio: `Arsalan Salamat, MD, is a board-certified breast surgeon and general surgeon. Dr. Salamat believes in listening to his patients to understand their unique health needs. From there, Dr. Salamat works with each patient to explore all of their options together as a team. `,
      providerPhilosophy: [],
      languages: ["English", "Farsi", "Spanish"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Illinois at Chicago College of Medicine",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "Presence Health St. Joseph Hospital in Chicago",
        },
        {
          type: "Fellowship",
          name: "University of Pittsburgh Medical Center in Pittsburgh, PA ",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Society of Breast Surgery",
        "Society of Surgical Oncology",
        "American Medical Association",
        "International College of Surgeons",
        "American College of Surgeons",
      ],
      publicationsAndResearch: [],
      providerLocations: [7, 8],
      id: 2,
    },

    {
      imageUrl: vatsalPatelgrid,
      providerName: "Vatsal B. Patel",
      providerTitle: "MD, MBA, DABR",
      linkUrl: "vatsal-patel",
      providerInteriorImage: vatsalPatel,
      specialty: ["Radiation Oncology"],
      sex: "Male",
      providerBio: `Vatsal Patel, MD, is a renowned radiation oncologist. He's had years of experience in the field. Dr. Patel has done many studies on different cancers. He's served as an academic leader at several schools. These include UConn, Yale and other programs. Dr. Patel always works to make sure patients understand each step of their care. He is kind and loves to help patients do well during their care.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "UConn School of Medicine, Farmington, Connecticut",
        },
        {
          type: "Internship",
          name: "Yale New Haven Hospital/Yale School of Medicine, Internal Medicine Department, New Haven, Connecticut",
        },
        {
          type: "Residency",
          name: "Mays Cancer Center, UT Health San Antonio MD Anderson Cancer Center • Yale New Haven Hospital/Yale School of Medicine, Diagnostic Radiology Department, New Haven, Connecticut",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [1, 3, 6, 3],
      id: 3,
    },

    {
      imageUrl: Foluso_Ogunleygrid,
      providerName: "Foluso N. Ogunleye",
      providerTitle: "MD",
      linkUrl: "foluso-ogunleye",
      providerInteriorImage: Foluso_Ogunley,
      specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
      sex: "Male",
      providerBio: `Foluso N. Ogunleye, MD, is a board-certified hematologist and medical oncologist. He also specializes in internal medicine. Dr. Ogunleye loves having the opportunity to improve the lives of his patients each day. He believes compassion is key to the proper delivery of medical care. He focuses on making sure patients feel comfortable and always make time to address patients concerns and questions.`,
      providerPhilosophy: [],
      languages: ["English", "Yoruba"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Benin School of Medicine in Benin, Nigeria",
        },
        {
          type: "Internship",
          name: "Roger William Medical Center, Boston University School of Medicine in Providence, RI",
        },
        {
          type: "Residency",
          name: "Roger William Medical Center, Boston University School of Medicine in Providence, RI",
        },
        {
          type: "Fellowship",
          name: "William Beaumont Hospital, Oakland University William Beaumont school of Medicine in Royal Oak, MI",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Society of Clinical Oncology",
        "American Society of Hematology",
        "American Medical Association",
      ],
      publicationsAndResearch: [],
      providerLocations: [1, 2],
      id: 6,
    },

    //More providers to be added
    //Category/Specialty updates for gallery page coming as well

    {
      imageUrl: johnEllertongrid,
      providerName: "John A. Ellerton",
      providerTitle: "MD",
      linkUrl: "john-ellerton",
      providerInteriorImage: johnEllerton,
      specialty: ["Medical Oncology", "Hematology"],
      sex: "Male",
      providerBio: `John A. Ellerton, MD, is a board-certified medical oncologist. Dr. Ellerton has been a practicing physician for over 35 years. He is a firm beliver in the power of research and what it can do to improve outcomes for cancer patients.  He is closely involved in various clinical trials, which are the scientific backbone of oncology. He also leads a state-wide cancer control and treatment program, which has been funded by the National Cancer Institute for more than 30 years. Dr. Ellerton works to educate his patients so they know understand their options as well as their treatment plans. He is an advocate for cancer prevention and screening for early detection. He loves changing lives and working directly with his patients to create treatment plans tailored to their needs.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Mcgill University in Montreal, Quebec",
        },
        {
          type: "Internship",
          name: "Mcgill University in Montreal, Quebec ",
        },
        {
          type: "Residency",
          name: "St. Michael's Hospital University of Toronto in Ontario",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 12,
    },

    {
      imageUrl: karissaTrangrid,
      providerName: "Karissa Tan",
      providerTitle: "APRN",
      linkUrl: "karissa-tan",
      providerInteriorImage: karissaTran,
      specialty: ["Medical Oncology", "Hematology"],
      sex: "Female",
      providerBio: `Karissa Tan, APRN, is a certified Advanced Practice Registered Nurse who practices hematology and medical oncology. Tan strives to bring compassion and kindness to every encounter with her patients. Tan believes in helping patients make informed decisions and loves to share her knowledge and resources with them. `,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Medicine and Dentistry of New Jersey, Newark, NJ",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Nurses Association",
        "American Association of Neuroscience Nurses",
      ],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 14,
    },

    {
      imageUrl: JahangirKhawajaSaadgrid,
      providerName: "Khawaja Jahangir",
      providerTitle: "MD",
      linkUrl: "khawaja-jahangir",
      providerInteriorImage: KhawajaJahangir,
      specialty: ["Medical Oncology", "Internal Medicine"],
      sex: "Male",
      providerBio: `Khawaja Jahangir, MD, is board-certified in internal medicine and medical oncology. Dr. Jahangir brings his extensive knowledge and passion for health care to OptumCare Cancer Care as a research director. He firmly believes that the best way to fight cancer is through education, clinical trials, and research surrounding new treatments to find the best solutions for his patients. `,
      providerPhilosophy: [],
      languages: ["English", "Urdu", "Punjabi"],
      qualifications: [
        {
          type: "Medical School",
          name: "Ross University in Bridgetown, Barbados",
        },
        {
          type: "Internship",
          name: "SUNY at Syracuse, in New York",
        },
        {
          type: "Residency",
          name: "University of Nevada in Reno, NV",
        },
        {
          type: "Fellowship",
          name: "Louisiana State University in Baton Rouge, LA",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 15,
    },

    {
      imageUrl: maryEseleGrid,
      providerName: "Mary Esele",
      providerTitle: "APRN",
      linkUrl: "mary-esele",
      providerInteriorImage: maryEsele,
      specialty: ["Breast Care"],
      sex: "Female",
      providerBio: `Mary Esele is an Advanced Practice Registered Nurse, APRN, and a Doctor of Nursing Practice, DNP, who specializes in breast care.  Esele is passionate about patient education and prides herself on her ability to establish clear lines of communication between patients and their family. She believes the best treatment plans are a teamwork effort. `,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Doctoral Degree at South University in Tampa, FL",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [3],
      id: 16,
    },

    {
      imageUrl: michaelBeckergrid,
      providerName: "Michael Becker",
      providerTitle: "DO",
      linkUrl: "michael-becker",
      providerInteriorImage: michaelBecker,
      specialty: ["Radiation Oncology"],
      sex: "Male",
      providerBio: `Michael Becker, DO, is a board-certified Doctor of Osteopathic Medicine who specializes in radiation oncology. Dr. Becker understands the importance of trust when establishing a doctor-patient relationship and makes sure to address all questions and concerns when explaining a new or difficult situation.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Des Moines University, in Des Moines, IA",
        },
        {
          type: "Internship",
          name: "San Antonio Uniformed Services Health Education Consortium, Brooke Army Medical Center in San Antonio, TX ",
        },
        {
          type: "Residency",
          name: "Wayne State University, Detroit Medical Center in Detroit, MI ",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 17,
    },

    {
      imageUrl: anaManaliligrid,
      providerName: "Ana Katrina Manalili",
      providerTitle: "MSN, APRN, FNP-C",
      linkUrl: "ana-manalili",
      providerInteriorImage: anaManalili,
      specialty: ["Radiation Oncology"],
      sex: "Female",
      providerBio: `Ana has worked in many specialties for the past 15 years. She graduated with honors with her master’s in nursing. Ana's a natural leader. She strives to always be learning. She was part of a research study on CyberKnife SBRT for prostate cancer. Ana loves caring for others. She has won two DAISY Awards. These awards are given to celebrate extraordinary nurses.`,
      providerPhilosophy: [],
      languages: ["English", "Spanish"],
      qualifications: [
        {
          type: "Medical School",
          name: "Touro University, Henderson, Nevada",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 18,
    },

    // {
    //     imageUrl: KurtzhalsPamelagrid,
    //     providerName: "Pamela L. Kurtzhals",
    //     providerTitle: "MD",
    //     linkUrl: "pamela-kurtzhals",
    //     providerInteriorImage: PamelaKurtzhals,
    //     specialty: ["Breast Care", "General Surgery"],
    //     sex: "Male",
    //     providerBio: `Pamela L.Kurtzhals, MD, is a board-certified general surgeon and breast surgeon. Dr. Kurtzhals believes in individualized patient care and focuses on tailoring treatment plans according to their specific needs and desires. Her compassionate approach builds relationships based on trust and communication in order to put patients at ease.`,
    //     providerPhilosophy: [],
    //     languages: ["English"],
    //     qualifications: [
    //         {
    //             type: 'Medical School',
    //             name: 'Wayne State University in Detroit, MI '
    //         },
    //         {
    //             type: 'Internship',
    //             name: ''
    //         },
    //         {
    //             type: 'Residency',
    //             name: 'Loyola University Medical Center in Hines, IL '
    //         },
    //         {
    //             type: 'Fellowship',
    //             name: ""
    //         },
    //         {
    //             type: 'Board certifications',
    //             name: ''
    //         }
    //     ],
    //     organizations: [],
    //     professionalAssociations: [],
    //     publicationsAndResearch: [],
    //     providerLocations: [4],
    //     id: 19
    // },

    /* {
            imageUrl: ritchieStevensgrid,
            providerName: "Ritchie Stevens",
            providerTitle: "MD",
            linkUrl: "ritchie-stevens",
            providerInteriorImage: ritchieStevens,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Ritchie Stevens, MD, is a board-certified radiation oncology specialist. Dr. Stevens believes in taking a compassionate approach when discussing options and listens to his patients’ concerns. He focuses on working directly with his patients as a team to develop treatment plans that can maximize a patient’s results.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Colorado School of Medicine in Boulder, CO'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [3],
            id: 21
        },*/

    // {
    //     imageUrl: robertBeckgrid,
    //     providerName: "Robert Beck",
    //     providerTitle: "MD",
    //     linkUrl: "robert-beck",
    //     providerInteriorImage: robertBeck,
    //     specialty: ["Radiation Oncology"],
    //     sex: "Male",
    //     providerBio: `Robert Beck, MD, is a board-certified radiation oncologist. Dr. Beck believes in providing care that is both compassionate and individualized. He strives to educate and empower his patients so they feel confident when it comes to decision making regarding their own health. Dr. Beck treats patients as if they were a part of his family. `,
    //     providerPhilosophy: [],
    //     languages: ["English"],
    //     qualifications: [
    //         {
    //             type: 'Medical School',
    //             name: 'University of Utah in Salt Lake City, UT '
    //         },
    //         {
    //             type: 'Internship',
    //             name: 'University of Kentucky in Lexington KY '
    //         },
    //         {
    //             type: 'Residency',
    //             name: 'Rutgers University in New Brunswick, NJ'
    //         },
    //         {
    //             type: 'Fellowship',
    //             name: ""
    //         },
    //         {
    //             type: 'Board certifications',
    //             name: ''
    //         }
    //     ],
    //     organizations: [],
    //     professionalAssociations: [],
    //     publicationsAndResearch: [],
    //     providerLocations: [5,6],
    //     id: 22
    // },

    {
      imageUrl: russellGollardgrid,
      providerName: "Russell P. Gollard",
      providerTitle: "MD",
      linkUrl: "russell-gollard",
      providerInteriorImage: russellGollard,
      specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
      sex: "Male",
      providerBio: `Russell P. Gollard, MD, is a board-certified medical oncologist and hematologist. For over 20 years, Dr. Gollard has worked to help others and improve their quality of care. As medical director of OptumCare Cancer Care, Dr. Gollard remains committed to his lifelong goal of improving cancer care for all. He seeks to build relationships based on trust by focusing on communication, education, and respect, to ensure optimal care is delivered in a way that is easy to understand. Dr. Gollard believes in patient education as well as participation and involvement in research. He prides himself on being up to date on the most recent scientific advances.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Southern California School of Medicine, Los Angeles, CA",
        },
        {
          type: "Internship",
          name: "University of Southern California Medical Center, Los Angeles, CA",
        },
        {
          type: "Residency",
          name: "University of Southern California Medical Center, Los Angeles, CA ",
        },
        {
          type: "Fellowship",
          name: "Scripps Clinic and Research Foundation in La Jolla, CA",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Society of Internal Medicine",
        "American College of Physicians-Fellow, Clark County Medical Society",
        "Nevada State Medical Association",
        "American Association of Cancer Research",
        "American Medical Association",
        "American Society of Clinical Oncology",
        "American Society of Hematology",
      ],
      publicationsAndResearch: [],
      providerLocations: [7, 8],
      id: 23,
    },

    {
      imageUrl: placeHolderMaleGrid,
      providerName: "Carlos Araujo",
      providerTitle: "MD",
      linkUrl: "carlos-araujo",
      providerInteriorImage: malePlaceholder,
      specialty: ["Medical Oncology", "Hematology"],
      sex: "Male",
      providerBio: `Carlos Araujo, MD, is a board-certified medical oncologist and hematologist. Dr. Araujo believes in a multidisciplinary approach to care. He focuses on working closely with his team to present his patients with options, information, and the time they need to ask questions. He prides himself in being an active listener and makes sure to put his patients first. `,
      providerPhilosophy: [],
      languages: ["English", "Portuguese", "Spanish"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Illinois at Chicago College of Medicine ",
        },
        {
          type: "Internship",
          name: "University of Chicago Medical Center in Chicago, IL  ",
        },
        {
          type: "Residency",
          name: "Lutheran General Hospital in Park Ridge, IL ",
        },
        {
          type: "Fellowship",
          name: "University of Colorado Health Sciences Center in Denver, CO ",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: [
        "American Society of Clinical Oncology",
        "Clark County Medical Society",
      ],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 24,
    },

    {
      imageUrl: edgardoFaylonaGrid,
      providerName: "Edgardo Faylona",
      providerTitle: "MD",
      linkUrl: "edgardo-faylona",
      providerInteriorImage: edgardoFaylona,
      specialty: ["Hematology"],
      sex: "Male",
      providerBio: `Dr. Faylona has worked in oncology for over three decades. Dr. Faylona chose oncology because he felt it was the most challenging field in medicine and one that is evolving rapidly. He is actively engaged in new oncology treatments that involve non-chemotherapy drugs.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of the Philippines ",
        },
        {
          type: "Internship",
          name: "University of the Philippines-Philippine General Hospital ",
        },
        {
          type: "Residency",
          name: "Ravenswood Hospital Medical Center",
        },
        {
          type: "Fellowship",
          name: "Indiana University Hospital Medical Center ",
        },
        {
          type: "Board certifications",
          name: "",
        },
      ],
      organizations: [],
      professionalAssociations: ["American Board of Internal Medicine"],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 25,
    },

    {
      imageUrl: okechukwuObiGrid,
      providerName: "Okechukwu Nwabueze",
      providerTitle: "Obi, MD, MPH",
      linkUrl: "kechukwu-nwabueze",
      providerInteriorImage: okechukwuObi,
      specialty: ["Hematology", "Oncology"],
      sex: "Male",
      providerBio: `Okechukwu Nwabueze Obi, MD, MPH, is board -certified in medical oncology, hematology and internal medicine. He was a doctor in the Nigerian army before coming to practice in the U.S. Dr. Obi is passionate about offering patients the best care possible. That’s why he stays up to date by researching the latest in cancer. He works together with patients so he can findto know all theirthe choices that work best for them and feel good about them. Dr. Obi believes in making time for each patient so that they feel supported. He’s a doctor that shows empathy and kindness to all.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of Benin, Benin City, Nigeria",
        },
        {
          type: "Residency",
          name: "John Stroger Hospital of Cook County, Chicago, Illinois",
        },
        {
          type: "Residency",
          name: "Ravenswood Hospital Medical Center",
        },
        {
          type: "Fellowship",
          name: "John Stroger Hospital of Cook County, Chicago, Illinois",
        },
        // {
        //   type: "Board certifications",
        //   name: "American Board of Internal Medicine, Hematology\n American Board of Internal Medicine, Medical Oncology \n American Board of Internal Medicine, Internal Medicine",
        // },
      ],
      organizations: [],
      professionalAssociations: [
        "American Board of Internal Medicine, Hematology",
        "American Board of Internal Medicine, Medical Oncology",
        "American Board of Internal Medicine, Internal Medicine",
      ],
      publicationsAndResearch: [],
      providerLocations: [2],
      id: 34,
    },

    {
      imageUrl: swethapentapatiGrid,
      providerName: "Swetha Pentapati",
      providerTitle: "MD",
      linkUrl: "swetha-pentapati",
      providerInteriorImage: swethapentapati,
      specialty: ["Hematology", "Oncology"],
      sex: "Female",
      providerBio: `Swetha Pentapati, MD, is a hematologist and medical oncologist. She was chief fellow and clinical instructor at Michigan State University. Dr. Pentapati also served on the cancer committee at Karmanos Cancer Institute. She has researched and published studies on colon and liver cancer. Dr. Pentapati enjoys helping her patients feel at ease in her care. She believes in being kind and understanding. Making a positive difference in their her patients’ lives every day is what means the most to Dr. Pentapati.`,
      providerPhilosophy: [],
      languages: ["English, Hindi, Kannada, and Telugu"],
      qualifications: [
        {
          type: "Medical School",
          name: "JJM Medical College, Davangere, Karnataka, India",
        },
        {
          type: "Residency",
          name: "McLaren Flint Hospital, Flint, Michigan",
        },
        {
          type: "Fellowship",
          name: "Michigan State University-McLaren Greater Lansing, Lansing, Michigan",
        },
        // {
        //   type: "Board certifications",
        //   name: "American Board of Internal Medicine, Hematology\n American Board of Internal Medicine, Medical Oncology \n American Board of Internal Medicine, Internal Medicine",
        // },
      ],
      organizations: [],
      professionalAssociations: [
        "American Board of Internal Medicine, Hematology",
        "American Board of Internal Medicine, Medical Oncology",
        "American Board of Internal Medicine, Internal Medicine",
      ],
      publicationsAndResearch: [],
      providerLocations: [1],
      id: 35,
    },

    /*{
            imageUrl: placeHolderFemaleGrid,
            providerName: "Vinh Nguyen",
            providerTitle: "APRN-BC, RN-BSN",
            linkUrl: "vinh-nguyen",
            providerInteriorImage: femalePlaceholder,
            specialty: ["Breast Care"],
            sex: "Female",
            providerBio: `Vinh Nguyen, APRN-BC, RN-BSN is a board-certified family nurse practitioner with extensive knowledge in obstetrics and gynecology. She believes in promoting women’s health and educating patients throughout the process. Vinh is a compassionate provider who cares for her patients as she would her own family. `,
            providerPhilosophy: [],
            languages: ["English", "Vietnamese"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Chamberlain College of Nursing in Las Vegas, NV '
                },
                {
                    type: 'Board certifications',
                    name: 'Family nurse practitioner'
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [7, 8],
            id: 26
        },*/

    {
      imageUrl: Hannah_Furneygrid,
      providerName: "Hannah Furney",
      providerTitle: "MSN, APRN, AGNP-C, AOCNP",
      linkUrl: "hannah-furney",
      providerInteriorImage: Hannah_Furney,
      specialty: ["Medical Oncology"],
      sex: "Female",
      providerBio: `Hannah Furney is an advanced oncology certified nurse practitioner. She's had many years of experience caring for patients as they go through cancer treatment.  She's a compassionate provider who listens to each patient and provides personalized care. Hannah believes in promoting healthy lifestyles to help her patients stay strong and feel well through treatment.`,
      providerPhilosophy: [],
      languages: ["English", "Korean"],
      qualifications: [
        {
          type: "Medical School",
          name: "University of South Alabama",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "Advanced Oncology Certified Nurse Practitioner, ONCC. Adult-Gerontology Primary Care Nurse Practitioner,  AANP.",
        },
      ],
      organizations: [],
      professionalAssociations: ["American Association of Nurse Practitioners"],
      publicationsAndResearch: [],
      providerLocations: [2],
      id: 27,
    },
    {
      imageUrl: pinkyGrid,
      providerName: "Pinky Linatoc",
      providerTitle: "APRN",
      linkUrl: "pinky-linatoc",
      providerInteriorImage: pinkyInterior,
      specialty: ["Breast Care"],
      sex: "Female",
      providerBio: `Pinky Linatoc is board-certified nurse practitioner that has been caring for patients for many years. Her approach is to consider the whole patient and how to keep them healthier. She believes in cancer prevention and educating her patients on early detection. Pinky considers it a privilege to care for those in her community. `,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Charles Drew University, Los Angeles, CA",
        },
        {
          type: "Internship",
          name: "",
        },
        {
          type: "Residency",
          name: "",
        },
        {
          type: "Fellowship",
          name: "",
        },
        {
          type: "Board certifications",
          name: "American Academy of Nurse Practitioners",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 28,
    },
    /*
        {
            imageUrl: gershmanGrid,
            providerName: "Eric A. Gershman",
            providerTitle: "MD",
            linkUrl: "eric-gershman",
            providerInteriorImage: gershman,
            specialty: ["Medical Oncology", "Hematology"],
            sex: "Male",
            providerBio: `Eric A. Gershman, MD, is a board-certified medical oncologist and hematologist. Dr. Gershman has over 23 years of experience treating cancer patients. He respects all patients and believes in putting them first. He offers patients with treatment options  that are right for them. Dr. Gresham always keeps patients desires top of mind. He focuses on providing comfort, improving quality of life, and recovery.`,
            providerPhilosophy: [],
            languages: ["English, Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Universidad Central Del Caribe in Bayamón, Puerto Rico'
                },
                {
                    type: 'Internship',
                    name: 'Louisiana State University in Baton Rouge, LA '
                },
                {
                    type: 'Residency',
                    name: 'Louisiana State University in Baton Rouge, LA '
                },
                {
                    type: 'Fellowship',
                    name: 'Memorial Sloan Kettering Cancer Center in New York, NY'
                },
                {
                    type: 'Board certifications',
                    name: 'American Academy of Nurse Practitioners'
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [3],
            id: 29
        },
 */
    {
      imageUrl: placeHolderMaleGrid,
      providerName: "Ronald Ganzon",
      providerTitle: "PA-C",
      linkUrl: "ronald-ganzon",
      providerInteriorImage: malePlaceholder,
      specialty: ["Breast Care"],
      sex: "Male",
      providerBio: `Ronald Ganzon is an experienced physician assistant. He has worked in many different specialties including emergency medicine, surgical care and gynecology. He focuses on understanding his patient's unique needs. Ronald is passionate about helping people find the right treatment plan to improve their condition. `,
      providerPhilosophy: [],
      languages: ["English, Spanish"],
      qualifications: [
        {
          type: "Medical School",
          name: "Touro University, Vallejo CA",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 30,
    },
    {
      imageUrl: darlaAdamsGrid,
      providerName: "Darla Adams",
      providerTitle: "APRN",
      linkUrl: "darla-adams",
      providerInteriorImage: darlaAdams,
      specialty: ["Breast Care"],
      sex: "Female",
      providerBio: `Darla has a background in critical care. She worked in the neurosurgical intensive care unit at Vanderbilt University Medical Center. She has been caring for patients in Las Vegas since 2014. She completed the emerging leadership program with UnitedHealth Group in 2017. Darla values being open and honest with her patients. She believes in keeping patients fully informed about their care. This allows them to feel confident about their health.`,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Touro University, Vallejo CA",
        },
        {
          type: "Board certifications",
          name: "American Nurses Credentialing Center",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 31,
    },
    {
      imageUrl: Jackline_Duanagrid,
      providerName: "Jackline Duana",
      providerTitle: "APRN",
      linkUrl: "jackline-duana",
      providerInteriorImage: Jackline_Duana,
      specialty: ["Breast Care"],
      sex: "Female",
      providerBio: `Jackline has spent years in the OB/GYN specialty. She understands the needs of patients during each stage of pregnancy. She also helps patients with general health concerns. Her passion is to create a unique treatment plan for each patient. Jackline feels privileged to care for her patients during their journey through life. `,
      providerPhilosophy: [],
      languages: ["English"],
      qualifications: [
        {
          type: "Medical School",
          name: "Walden University in Minneapolis, MN",
        },
        {
          type: "Board certifications",
          name: "American Nurses Credentialing Center",
        },
      ],
      organizations: [],
      professionalAssociations: [],
      publicationsAndResearch: [],
      providerLocations: [4],
      id: 32,
    },
  ],
  buttons: [],
};

export default PROVIDERS_DATA;
